import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="error-wrapper">
        <img
          src={
            "/under-construction.png"
          }
          width="256"
          height={"256"}
          alt="404"
        />
        <h3>
          Oops! This website is under construction...
        </h3>
      </div>
    </div>
  );
}

export default App;
